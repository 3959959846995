.datepicker {
  border-style: solid;
  border-width: 1px;
  border: none;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #bebcbc;
  height: 44px;
  padding: 10px;
  width: 100%;
}

.react-datepicker {
  font-size: 1em;
}
.react-datepicker__header {
  padding-top: 0.8em;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
.react-datepicker__current-month {
  font-size: 1em;
}
.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}

.react-datepicker-popper,
.react-datepicker--time-only,
.react-datepicker__time-container,
.react-datepicker__time-box {
  width: 100% !important;
}

.selectPlaceholder {
  color: #696969;
}
