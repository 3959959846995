/*Progress steps styles*/
body {
  background-color: #f7fafc;
  font-family: 'Josefin Sans', sans-serif;
}

.progressbar {
  counter-reset: step;
  width: 70%;
  float: right;
}
.progressbar li {
  list-style-type: none;
  width: 33%;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
}
.progressbar li:before {
  width: 30px;
  height: 30px;
  content: counter(step);
  counter-increment: step;
  line-height: 30px;
  border: 2px solid #7d7d7d;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: #ffffff;
}
.progressbar li:after {
  width: 100%;
  height: 2px;
  content: '';
  position: absolute;
  background-color: #7d7d7d;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  color: #38b6ff;
}
.progressbar li.active:before {
  border-color: #38b6ff;
}
.progressbar li.active + li:after {
  background-color: #38b6ff;
}

/*Inputs ranges in register*/

input[type='range'] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #38b6ff, 0px 0px 0px #38b6ff;
  background: #38b6ff;
  border-radius: 25px;
  border: 0px solid #38b6ff;
}
input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #38b6ff, 0px 0px 0px #38b6ff;
  border: 0px solid #38b6ff;
  height: 20px;
  width: 20px;
  background: #38b6ff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -9px;
  border-radius: 100px;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: #38b6ff;
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #38b6ff, 0px 0px 0px #38b6ff;
  background: #38b6ff;
  border-radius: 100px;
  border: 0px solid #38b6ff;
}
input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px #38b6ff, 0px 0px 0px #38b6ff;
  border: 0px solid #38b6ff;
  height: 20px;
  width: 20px;
  border-radius: 100px;
  background: #38b6ff;
  cursor: pointer;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 12.8px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 39px 0;
  color: transparent;
  border-radius: 100px;
}
input[type='range']::-ms-fill-lower {
  background: #38b6ff;
  border: 0px solid #38b6ff;
  border-radius: 100px;
  box-shadow: 0px 0px 0px #38b6ff, 0px 0px 0px #38b6ff;
}
input[type='range']::-ms-fill-upper {
  background: #38b6ff;
  border: 0px solid #38b6ff;
  border-radius: 100px;
  box-shadow: 0px 0px 0px #38b6ff, 0px 0px 0px #38b6ff;
}
input[type='range']::-ms-thumb {
  box-shadow: 0px 0px 0px #38b6ff, 0px 0px 0px #38b6ff;
  border: 0px solid #38b6ff;
  height: 3px;
  width: 20px;
  border-radius: 100px;
  background: #38b6ff;
  cursor: pointer;
}
input[type='range']:focus::-ms-fill-lower {
  background: #38b6ff;
}
input[type='range']:focus::-ms-fill-upper {
  background: #38b6ff;
}

/* Lateral Menu */

.icon-stack {
  position: relative;
  line-height: 4em;
}
.icon-stack-1x,
.icon-stack-3x {
  position: absolute;
  left: 0;
  width: 100%;
  display: block !important;
  font-size: 18px !important;
}
.superpuestIcon {
  margin-top: 13px;
  height: 30px;
}
.icon-stack-1x {
  line-height: inherit;
  margin-left: -7px;
  margin-top: -32px;
}

.far,
.fa {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
}

.main-menu,
nav.main-menu {
  width: 250px;
}
@media (max-width: 700px) {
  .main-menu,
  nav.main-menu {
    width: 55px;
  }
  .nav-text-title {
    display: none;
  }
}

.main-menu {
  background: #ffffff;
  border-right: 1px solid #e5e5e5;
  top: 71px;
  bottom: 0;
  height: 100%;
  left: 0;
  width: 60px;
  overflow: hidden;
  -webkit-transition: width 0.05s linear;
  transition: width 0.05s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  z-index: 1000;
  position: fixed;
}

.main-menu > ul {
  margin: 7px 0;
}

.menu-item {
  position: relative;
  display: block;
  width: 250px;
  height: 50px;
  cursor: pointer;
}

.menu-item > a {
  position: relative;
  display: table;
  border-spacing: 0;
  color: #999;
  font-family: arial;
  font-size: 14px;
  text-decoration: none;
  -webkit-transform: translateZ(0) scale(1, 1);
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  height: 50px;
}

.main-menu .nav-icon {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
}

.main-menu .nav-text {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 190px;
  font-family: 'Titillium Web', sans-serif;
}

.no-touch .scrollable.hover {
  overflow-y: hidden;
}

.no-touch .scrollable.hover:hover {
  overflow-y: auto;
  overflow: visible;
}

a:hover,
a:focus {
  text-decoration: none;
}
button:focus {
  outline: 0;
}

nav {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

nav ul,
nav li {
  outline: 0;
  margin: 0;
  padding: 0;
}
.menu-item:hover > a,
nav.menu-item.active > a,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus,
.no-touch .dashboard-page nav.dashboard-menu ul li:hover a,
.dashboard-page nav.dashboard-menu ul li.active a {
  color: #38b6ff;
  background-color: #ebf8ff;
}

.title-menu {
  height: 70px;
  padding-left: 22px;
  padding-top: 20px;
}
.title-menu span {
  color: #999;
}
.activeMenu {
  background-color: #ebf8ff;
}
.activeMenu span {
  color: #38b6ff;
}
.activeMenu i {
  color: #38b6ff;
}

/* icons */
i {
  cursor: pointer;
}

/* steps line bar */
.react-sweet-progress-line-inner {
  border-radius: 0px;
  min-height: 4px;
  background-color: #38b6ff !important;
}
.react-sweet-progress-symbol {
  display: none;
}
