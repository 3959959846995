.profileImage {
  width: 50px;
  border-radius: 30px;
}
.calendarImage {
  width: 30px;
  height: 30px;
  margin-top: 8px;
}
.flagImage {
  width: 45px;
  height: 30px;
  margin-top: 5px;
  border-radius: 3px;
}
.inboxImage {
  width: 25px;
}

.without-margin {
  margin: 0px !important;
}

.icons {
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  height: 30px !important;
  width: 30px !important;
}

.completed {
  color: #00af04;
}

.rejected {
  color: #fc2727;
}

.pending {
  color: #ef8354;
}

.in_process {
  color: #9e9e9e;
  opacity: 0.5;
}

.non-meeting {
  color: #9e9e9e;
}

.link {
  margin-top: 25px;
}
.link > a {
  color: #9e9e9e;
}
