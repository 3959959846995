.datepickerTrip {
  border-style: solid;
  border-width: 1px;
  border: none;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #b7b7b7;
  height: 44px;
  padding: 10px;
  width: 100%;
}

.datepickerTrip::placeholder {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 14px;
  letter-spacing: 0.42px;
  color: #b7b7b7;
}

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker {
  display: table;
}
