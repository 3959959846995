.react-datepicker-wrapper {
  width: 100%;
}

.datepickerActivity {
  width: 100%;
  height: 50px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 6px 18px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: none;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
  padding-left: 20px;
}

.datepickerActivity::placeholder {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  color: #999999;
}
