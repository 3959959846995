.StripeElement {
  border-style: solid;
  border-width: 1px;
  border: none;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #bebcbc;
  height: 40px;
  margin-top: 15px;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}
